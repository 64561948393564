/* eslint-disable no-unused-vars */
export enum PropertyStatus {
  CAPTURED = 2,
  PUBLISHED = 3,
  PENDING_SIGNATURE = 5,
  RENTAL_MANAGEMENT = 15,
  LOST = 7,
  LOST_NOT_PUBLISHED = 13,
  PENDING_RECOVER = 18,
}
